/* Importaciones de Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Sobrescribe la altura de html y body */
html, body {
  height: auto;
}

/* Resto de tus estilos personalizados */
/* Estilos para el scroll global */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.product-carousel .slick-track {
  display: flex !important;
  margin-left: 0 !important;
}

.product-carousel .slick-slide {
  height: inherit !important;
}

.product-carousel .slick-slide > div {
  height: 100%;
}

.product-carousel .slick-prev,
.product-carousel .slick-next {
  z-index: 10;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #ffffff;
  border-radius: 5px;
  border: 2px solid #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333 #1a1a1a;
}

/* Clases de utilidad para scrollbars específicos */
.dark-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #333 #1a1a1a;
}

.dark-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dark-scrollbar::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.dark-scrollbar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
  border: 2px solid #1a1a1a;
}

.dark-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #444;
}

/* Scrollbar más delgado para elementos específicos */
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #333 transparent;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 3px;
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos personalizados */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */ /* Comentado para permitir que el contenido defina la altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */ /* Comentado para evitar centrar verticalmente */
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
